import {
  PageData,
  PageMetaDataList,
  FooterType,
  QuickNavType,
  InboxMessage,
  OutletCartType,
  OutletHistorySpecificType,
  OutletHistoryGeneral,
  LexiconSearch,
  MyMilkLabData,
  MyMilkMilkMoneyData,
  MyMilkAccountingData,
  MyMilkYearlyData,
  WagtailStreamfieldImage,
  GroupManagementGroupType,
  SupplierData,
  MilkingTimesType,
  PlotTypeWithId,
  PastureCalendarType,
  PastureCalendarDotType,
  PastureCalendarSubmitType,
  PlotMapType,
  KPMQuestionType,
  KPMQuestionDataType,
  KPMStatType,
  MyMilkSpecialSamplesData,
  KPMPresubmitType,
  BonusCodeData,
} from '@/types';
import { RequestInit } from 'next/dist/server/web/spec-extension/request';
import { ParsedUrlQuery } from 'querystring';
import { signOut } from 'next-auth/react';
import { UserData } from 'next-auth';

const API_URL = process.env.WAGTAIL_API_URL ?? process.env.NEXT_PUBLIC_API_URL;
const API_VERSION = process.env.WAGTAIL_API_VERSION ?? process.env.NEXT_PUBLIC_API_VERSION;
const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;

export class WagtailApiResponseError extends Error {
  response: Response;

  constructor(res: Response, url: string, params: any) {
    super(`${res.status} - ${res.statusText}. Url: ${url}. Params: ${JSON.stringify(params)}`);
    this.name = 'WagtailApiResponseError';
    this.response = res;
  }
}

type Params = Record<string, string | readonly string[]>;

export async function getRequest<T>(
  url: string,
  params: ParsedUrlQuery = {},
  options: Partial<RequestInit> = {},
) {
  let headers = options?.headers ?? {};
  headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...headers,
  };

  const queryString = new URLSearchParams(params as any);
  const res = await fetch(`${API_URL}/${API_VERSION}/${url}?${queryString.toString()}`, {
    ...options,
    headers,
  });

  if (res.status < 200 || res.status >= 300) {
    if (res.status === 401 || res.status === 403) {
      signOut({ redirect: true, callbackUrl: '/auth/signin' });
    }

    const error = new WagtailApiResponseError(res, url, params);
    throw error;
  }

  const json: T = await res.json();
  return {
    headers: res.headers,
    json,
  };
}

export async function getPage(
  path: string,
  params: Params = {},
  options: Partial<RequestInit> = {},
) {
  const page = await getRequest<PageData>(
    'pages/find/',
    { ...params, html_path: path !== '/[...path]' && path ? path : '/' },
    options,
  );
  return page;
}

export async function getMenu(options: Partial<RequestInit> = {}) {
  return getRequest<PageMetaDataList[]>(
    `wagtailmenus_api/v1/main_menu/?current_url=${BASE_URL}`,
    undefined,
    options,
  );
}

export async function getFooterSettings(options: Partial<RequestInit> = {}) {
  return getRequest<FooterType>(`settings/footer/`, undefined, options);
}

export async function getQuickNav(options: Partial<RequestInit> = {}) {
  return getRequest<QuickNavType>(`settings/quicknav/`, undefined, options);
}

export async function getAllPages() {
  const pages = await getRequest<PageMetaDataList>('pages/');
  return pages;
}

export async function getPagePreview(
  content_type: string,
  token: string,
  params: any = {},
  options: any = {},
) {
  // get id out of token
  const id = token.replace(/^id=([\d]+).*/gm, '$1');

  const res = await getRequest<PageData>(
    `page_preview/${id}/`,
    { ...params, content_type, token },
    options,
  );

  return res;
}

export async function getFlatMenu(handle: string) {
  const res = await getRequest<PageMetaDataList[]>(
    `wagtailmenus_api/v1/flat_menu/?current_url=${BASE_URL}&handle=${handle}`,
  );

  return res;
}

export async function searchSite(query: ParsedUrlQuery, options: Partial<RequestInit> = {}) {
  const result = await getRequest<PageMetaDataList>('pages/', query, options);
  return result;
}

export async function searchLexicon(query: ParsedUrlQuery, options: Partial<RequestInit> = {}) {
  const result = await getRequest<LexiconSearch>('pages/', query, options);
  return result.json.items;
}

export async function getMyProfile(options: Partial<RequestInit> = {}) {
  return getRequest<UserData>(`users/me/`, undefined, options);
}

export async function getMySupplier(options: Partial<RequestInit> = {}) {
  return getRequest<SupplierData>(`supplier/supplier/`, undefined, options);
}

export async function getOgtExport(authToken: string) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/ogt_export/ogt_pdf/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/pdf',
      Authorization: authToken,
    },
  });
  if (submit.status < 200 || submit.status >= 300) {
    const error = new WagtailApiResponseError(submit, '/ogt_export/ogt_pdf/', undefined);
    throw error;
  }
  return submit;
}

export async function getInbox(options: Partial<RequestInit> = {}) {
  return getRequest<InboxMessage[]>(`inbox/`, undefined, options);
}

export async function getInboxMessage(id: number, options: Partial<RequestInit> = {}) {
  return getRequest<InboxMessage>(`inbox/${id}/`, undefined, options);
}

export async function getInboxUnreadNumber(options: Partial<RequestInit> = {}) {
  return getRequest<{ count: number }>(`inbox/num-unread/`, undefined, options);
}

export async function setInboxAllRead(authToken: string) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/inbox/mark-read/all/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken,
    },
  });
  if (submit.status < 200 || submit.status >= 300) {
    if (submit.status === 401 || submit.status === 403) {
      signOut({ redirect: true, callbackUrl: '/auth/signin' });
    }
    const error = new WagtailApiResponseError(submit, '/inbox/mark-read/all/', undefined);
    throw error;
  }

  return submit.status;
}

export async function signUrls(url: string, authToken: string) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/sign-urls/sign/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken,
    },
    body: JSON.stringify({ url }),
  });
  if (submit.status < 200 || submit.status >= 300) {
    const error = new WagtailApiResponseError(submit, '/sign-urls/sign/', undefined);
    throw error;
  }
  return submit;
}

export async function getRandomImage(options: Partial<RequestInit> = {}) {
  const result = await getRequest<WagtailStreamfieldImage[]>('random_image/', undefined, options);
  return result;
}

export async function getOutletCart(options: Partial<RequestInit> = {}) {
  return getRequest<OutletCartType>(`shop/cart/`, undefined, options);
}

export async function getOutletOrders(options: Partial<RequestInit> = {}) {
  return getRequest<OutletHistoryGeneral[]>(`shop/orders/`, undefined, options);
}

export async function getSpecificOutletOrder(id: number, options: Partial<RequestInit> = {}) {
  return getRequest<OutletHistorySpecificType>(`shop/orders/${id}`, undefined, options);
}

export async function addToOutletCart(
  data: { amount: number; article_item_id: number },
  authToken: string,
) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/shop/cart/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken,
    },
    body: JSON.stringify(data),
  });
  if (submit.status < 200 || submit.status >= 300) {
    const error = new WagtailApiResponseError(submit, '/shop/cart/', undefined);
    throw error;
  }
  return submit;
}

export async function removeFromOutletCart(authToken: string, data: { article_item_id: number }) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/shop/cart/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken,
    },
    body: JSON.stringify(data),
  });
  if (submit.status < 200 || submit.status >= 300) {
    const error = new WagtailApiResponseError(submit, '/shop/cart/', undefined);
    throw error;
  }
  return submit;
}

export async function submitOutletCart(authToken: string) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/shop/cart/submit/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken,
    },
  });
  if (submit.status < 200 || submit.status >= 300) {
    const error = new WagtailApiResponseError(submit, '/shop/cart/submit/', undefined);
    throw error;
  }

  return submit;
}

export async function submitForm(data: any) {
  const submit = await getRequest('forms/', undefined, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return submit;
}

export async function validateToken(token: Object) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/password-reset/validate/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(token),
  });
  if (submit.status < 200 || submit.status >= 300) {
    const error = new WagtailApiResponseError(submit, '/password-reset/validate/', undefined);
    throw error;
  }
  return submit;
}

export async function submitPasswordReset(data: Object) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/password-reset/confirm/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (submit.status < 200 || submit.status >= 300) {
    const error = new WagtailApiResponseError(submit, '/password-reset/confirm/', undefined);
    throw error;
  }

  return submit;
}

export async function submitRequestPasswordReset(data: Object) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/password-reset/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (submit.status < 200 || submit.status >= 300) {
    const error = new WagtailApiResponseError(submit, '/password-reset/', undefined);
    throw error;
  }
  return submit;
}

export async function submitPasswordChange(data: Object, authToken: string) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/users/me/set-password/`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken,
    },
    body: JSON.stringify(data),
  });
  if (submit.status < 200 || submit.status >= 300) {
    if (submit.status === 401 || submit.status === 403) {
      signOut({ redirect: true, callbackUrl: '/auth/signin' });
    }
    const error = new WagtailApiResponseError(submit, '/users/me/set-password/', undefined);
    throw error;
  }

  return submit;
}

export async function submitEventRegistration(data: Object, authToken: string) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/events/registrations/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken,
    },
    body: JSON.stringify(data),
  });
  if (submit.status < 200 || submit.status >= 300) {
    if (submit.status === 401 || submit.status === 403) {
      signOut({ redirect: true, callbackUrl: '/auth/signin' });
    }
    const error = new WagtailApiResponseError(submit, '/events/registrations/', undefined);
    throw error;
  }

  return submit;
}

export async function submitMultipleEventRegistration(data: Object, authToken: string) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/events/registrations/multiple/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken,
    },
    body: JSON.stringify(data),
  });
  if (submit.status < 200 || submit.status >= 300) {
    if (submit.status === 401 || submit.status === 403) {
      signOut({ redirect: true, callbackUrl: '/auth/signin' });
    }
    const error = new WagtailApiResponseError(submit, '/events/registrations/multiple/', undefined);
    throw error;
  }

  return submit;
}

export async function submitEmailConsent(authToken: string, data: Object) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/users/me/`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken,
    },
    body: JSON.stringify(data),
  });
  if (submit.status < 200 || submit.status >= 300) {
    if (submit.status === 401 || submit.status === 403) {
      signOut({ redirect: true, callbackUrl: '/auth/signin' });
    }
    const error = new WagtailApiResponseError(submit, '/users/me/', undefined);
    throw error;
  }

  return submit;
}

// Werder Bremen
export async function getBonusCode(options: Partial<RequestInit> = {}) {
  return getRequest<BonusCodeData>(`werder-bremen/`, undefined, options);
}

// MyMilk

export async function myMilkGetAccounting(
  query: ParsedUrlQuery,
  options: Partial<RequestInit> = {},
) {
  const result = await getRequest<MyMilkAccountingData[]>(
    'my-milk/accounting_data/',
    query,
    options,
  );
  return result;
}

export async function myMilkGetLab(query: ParsedUrlQuery, options: Partial<RequestInit> = {}) {
  const result = await getRequest<MyMilkLabData[]>('my-milk/lab_data/', query, options);
  return result;
}

export async function myMilkGetMilkMoney(
  query: ParsedUrlQuery,
  options: Partial<RequestInit> = {},
) {
  const result = await getRequest<MyMilkMilkMoneyData[]>('my-milk/milk_money/', query, options);
  return result;
}

export async function myMilkGetSpecialSamples(options: Partial<RequestInit> = {}) {
  const result = await getRequest<MyMilkSpecialSamplesData[]>(
    'my-milk/special_samples/',
    undefined,
    options,
  );
  return result;
}

export async function myMilkGetYearly(options: Partial<RequestInit> = {}) {
  const result = await getRequest<MyMilkYearlyData[]>('my-milk/yearly_values/', undefined, options);
  return result;
}

export async function myMilkGetLabExcel(query: ParsedUrlQuery, authToken: string) {
  const queryString = new URLSearchParams(query as any);
  const res = await fetch(
    `${API_URL}/${API_VERSION}/my-milk/lab_data/export_to_excel/?${queryString.toString()}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/octet-stream',
        Authorization: authToken,
      },
    },
  );
  if (res.status < 200 || res.status >= 300) {
    const error = new WagtailApiResponseError(res, '/my-milk/lab_data/export_to_excel/', undefined);
    throw error;
  }
  return res;
}

export async function myMilkGetMilkMoneyExcel(query: ParsedUrlQuery, authToken: string) {
  const queryString = new URLSearchParams(query as any);
  const res = await fetch(
    `${API_URL}/${API_VERSION}/my-milk/milk_money/export_to_excel/?${queryString.toString()}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/octet-stream',
        Authorization: authToken,
      },
    },
  );
  if (res.status < 200 || res.status >= 300) {
    const error = new WagtailApiResponseError(
      res,
      'my-milk/milk_money/export_to_excel/',
      undefined,
    );
    throw error;
  }
  return res;
}

export async function myMilkGetYearlyExcel(authToken: string) {
  const res = await fetch(`${API_URL}/${API_VERSION}/my-milk/yearly_values/export_to_excel/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/octet-stream',
      Authorization: authToken,
    },
  });
  if (res.status < 200 || res.status >= 300) {
    const error = new WagtailApiResponseError(
      res,
      'my-milk/yearly_values/export_to_excel/',
      undefined,
    );
    throw error;
  }
  return res;
}

export async function myMilkGetAccountingExcel(query: ParsedUrlQuery, authToken: string) {
  const queryString = new URLSearchParams(query as any);
  const res = await fetch(
    `${API_URL}/${API_VERSION}/my-milk/accounting_data/export_to_excel/?${queryString.toString()}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/octet-stream',
        Authorization: authToken,
      },
    },
  );
  if (res.status < 200 || res.status >= 300) {
    const error = new WagtailApiResponseError(
      res,
      'my-milk/accounting_data/export_to_excel/',
      undefined,
    );
    throw error;
  }
  return res;
}

export async function myMilkGetSpecialSamplesExcel(authToken: string) {
  const res = await fetch(`${API_URL}/${API_VERSION}/my-milk/special_samples/export_to_excel/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/octet-stream',
      Authorization: authToken,
    },
  });
  if (res.status < 200 || res.status >= 300) {
    const error = new WagtailApiResponseError(
      res,
      'my-milk/special_samples/export_to_excel/',
      undefined,
    );
    throw error;
  }
  return res;
}

// PastureCalendar
export async function getGroupManagementGroups(options: Partial<RequestInit> = {}) {
  const result = await getRequest<GroupManagementGroupType[]>(
    'supplier/group_management/',
    undefined,
    options,
  );
  return result;
}

export async function createGroupManagementGroup(data: Object, authToken: string) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/supplier/group_management/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken,
    },
    body: JSON.stringify(data),
  });
  if (submit.status < 200 || submit.status >= 300) {
    if (submit.status === 401 || submit.status === 403) {
      signOut({ redirect: true, callbackUrl: '/auth/signin' });
    }
    const error = new WagtailApiResponseError(submit, '/supplier/group_management/', undefined);
    throw error;
  }

  return submit;
}

export async function updateGroupManagementGroup(data: Object[], authToken: string) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/supplier/group_management/`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken,
    },
    body: JSON.stringify(data),
  });
  if (submit.status < 200 || submit.status >= 300) {
    if (submit.status === 401 || submit.status === 403) {
      signOut({ redirect: true, callbackUrl: '/auth/signin' });
    }
    const error = new WagtailApiResponseError(submit, `/supplier/group_management/`, undefined);
    throw error;
  }

  return submit;
}

export async function getPlots(options: Partial<RequestInit> = {}) {
  const result = await getRequest<PlotTypeWithId[]>('supplier/plot/', undefined, options);
  return result;
}

export async function createPlots(data: Object, authToken: string) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/supplier/plot/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken,
    },
    body: JSON.stringify(data),
  });
  if (submit.status < 200 || submit.status >= 300) {
    if (submit.status === 401 || submit.status === 403) {
      signOut({ redirect: true, callbackUrl: '/auth/signin' });
    }
    const error = new WagtailApiResponseError(submit, '/supplier/plot/', undefined);
    throw error;
  }

  return submit;
}

export async function updatePlots(data: Object[], authToken: string) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/supplier/plot/`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken,
    },
    body: JSON.stringify(data),
  });
  if (submit.status < 200 || submit.status >= 300) {
    if (submit.status === 401 || submit.status === 403) {
      signOut({ redirect: true, callbackUrl: '/auth/signin' });
    }
    const error = new WagtailApiResponseError(submit, `/supplier/plot/`, undefined);
    throw error;
  }

  return submit;
}

export async function getMilkingTimes(options: Partial<RequestInit> = {}) {
  const result = await getRequest<MilkingTimesType[]>('supplier/milkingtimes/', undefined, options);
  return result;
}

export async function updateMilkingTimes(data: MilkingTimesType, authToken: string) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/supplier/milkingtimes/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken,
    },
    body: JSON.stringify(data),
  });
  if (submit.status < 200 || submit.status >= 300) {
    if (submit.status === 401 || submit.status === 403) {
      signOut({ redirect: true, callbackUrl: '/auth/signin' });
    }
    const error = new WagtailApiResponseError(submit, `/supplier/milkingtimes/`, undefined);
    throw error;
  }

  return submit;
}

export async function getPlotMap(options: Partial<RequestInit> = {}) {
  const result = await getRequest<PlotMapType>('supplier/plot_map/', undefined, options);
  return result;
}

export async function postPlotMap(data: FormData, authToken: string) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/supplier/plot_map/`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
    },
    body: data,
  });
  if (submit.status < 200 || submit.status >= 300) {
    if (submit.status === 401 || submit.status === 403) {
      signOut({ redirect: true, callbackUrl: '/auth/signin' });
    }
    const error = new WagtailApiResponseError(submit, `/supplier/plot_map/`, undefined);
    throw error;
  }

  return submit;
}

export async function myMilkDashboardGetLastPickup(options: Partial<RequestInit> = {}) {
  const result = await getRequest<MyMilkLabData>('my-milk/last_pickup/', undefined, options);
  return result;
}

export async function myMilkDashboardGetLastLab(options: Partial<RequestInit> = {}) {
  const result = await getRequest<MyMilkLabData>('my-milk/last_lab_data/', undefined, options);
  return result;
}

export async function getPastureCalendar(
  query: ParsedUrlQuery,
  options: Partial<RequestInit> = {},
) {
  const result = await getRequest<PastureCalendarType[]>('calendar/', query, options);
  return result;
}

export async function getPastureCalendarDots(options: Partial<RequestInit> = {}) {
  const result = await getRequest<PastureCalendarDotType>('calendar/dots/', undefined, options);
  return result;
}

export async function getPastureCalendarAudit(
  query: ParsedUrlQuery,
  options: Partial<RequestInit> = {},
) {
  const result = await getRequest<{ days_over_six_hours: number }>(
    'calendar/audit/',
    query,
    options,
  );
  return result;
}

export async function createPastureCalendarEntry(
  data: PastureCalendarSubmitType,
  authToken: string,
) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/calendar/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken,
    },
    body: JSON.stringify(data),
  });
  if (submit.status < 200 || submit.status >= 300) {
    if (submit.status === 401 || submit.status === 403) {
      signOut({ redirect: true, callbackUrl: '/auth/signin' });
    }
    const error = new WagtailApiResponseError(submit, `/calendar/`, undefined);
    throw error;
  }

  return submit;
}

export async function updatePastureCalendarEntry(
  data: PastureCalendarSubmitType,
  id: number,
  authToken: string,
) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/calendar/${id}/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken,
    },
    body: JSON.stringify(data),
  });
  if (submit.status < 200 || submit.status >= 300) {
    if (submit.status === 401 || submit.status === 403) {
      signOut({ redirect: true, callbackUrl: '/auth/signin' });
    }
    const error = new WagtailApiResponseError(submit, `/calendar/${id}/`, undefined);
    throw error;
  }

  return submit;
}

export async function deltePastureCalendarEntry(id: number, authToken: string) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/calendar/${id}/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken,
    },
  });
  if (submit.status < 200 || submit.status >= 300) {
    if (submit.status === 401 || submit.status === 403) {
      signOut({ redirect: true, callbackUrl: '/auth/signin' });
    }
    const error = new WagtailApiResponseError(submit, `/calendar/${id}/`, undefined);
    throw error;
  }

  return submit.status;
}

// KPM
export async function getKpmAllQuestions(options: Partial<RequestInit> = {}) {
  const result = await getRequest<KPMQuestionDataType>('kpm/questions', undefined, options);
  return result;
}

export async function getKpmQuestion(id: string, options: Partial<RequestInit> = {}) {
  const result = await getRequest<KPMQuestionType>(`kpm/questions/${id}`, undefined, options);
  return result;
}

export async function getKpmStats(options: Partial<RequestInit> = {}) {
  const result = await getRequest<KPMStatType[]>('kpm/stats', undefined, options);
  return result;
}

export async function getKpmPreSubmitData(options: Partial<RequestInit> = {}) {
  const result = await getRequest<KPMPresubmitType>('kpm/pre_submit_data', undefined, options);
  return result;
}

export async function getKpmFootprintChange(
  query: ParsedUrlQuery,
  options: Partial<RequestInit> = {},
) {
  const result = await getRequest<{ footprint_change: number }>(
    'kpm/footprint_change',
    query,
    options,
  );
  return result;
}

export async function postKpmCalculation(data: any, identifier: string, authToken: string) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/kpm/calculate/${identifier}/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken,
    },
    body: JSON.stringify(data),
  });
  if (submit.status < 200 || submit.status >= 300) {
    if (submit.status === 401 || submit.status === 403) {
      signOut({ redirect: true, callbackUrl: '/auth/signin' });
    }
    const error = new WagtailApiResponseError(submit, `/kpm/calculate/${identifier}/`, undefined);
    throw error;
  }

  return submit;
}

export async function setKpmAnswer(
  data: {
    [key: string]: FormDataEntryValue | FormDataEntryValue[];
  },
  authToken: string,
) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/kpm/set_answer/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken,
    },
    body: JSON.stringify(data),
  });
  if (submit.status < 200 || submit.status >= 300) {
    if (submit.status === 401 || submit.status === 403) {
      signOut({ redirect: true, callbackUrl: '/auth/signin' });
    }
    const error = new WagtailApiResponseError(submit, `/kpm/set_answer/`, undefined);
    throw error;
  }

  return submit;
}

export async function submitKpmForm(
  authToken: string,
  data?: {
    comments: string;
    reducing_potential: string;
    measures_emissions: number[];
  },
) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/kpm/submit/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken,
    },
    body: JSON.stringify(data),
  });
  if (submit.status < 200 || submit.status >= 300) {
    if (submit.status === 401 || submit.status === 403) {
      signOut({ redirect: true, callbackUrl: '/auth/signin' });
    }
    const error = new WagtailApiResponseError(submit, `/kpm/submit/`, undefined);
    throw error;
  }

  return submit;
}

export async function getKpmPrivacy(options: Partial<RequestInit> = {}) {
  const result = await getRequest<{ approved: boolean }>(
    'kpm/data-privacy-approval',
    undefined,
    options,
  );
  return result;
}

export async function submitKpmPrivacy(authToken: string) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/kpm/data-privacy-approval/`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken,
    },
  });
  if (submit.status < 200 || submit.status >= 300) {
    if (submit.status === 401 || submit.status === 403) {
      signOut({ redirect: true, callbackUrl: '/auth/signin' });
    }
    const error = new WagtailApiResponseError(submit, `/kpm/data-privacy-approval/`, undefined);
    throw error;
  }

  return submit;
}
