import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';

type Props = {
  isSignIn: boolean;
  isLoggedIn: boolean;
};

function Main(props: PropsWithChildren<Props>) {
  const { children, isSignIn, isLoggedIn } = props;

  return (
    <main className={clsx('main', { 'main--full-width': isSignIn || !isLoggedIn })}>
      {children}
    </main>
  );
}

export default Main;
