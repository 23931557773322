import React from 'react';
import Link from 'next/link';
import { getFooterSettings } from '@api/wagtail';
import { useQuery } from '@tanstack/react-query';
import { FooterType } from '@/types';
import { removeBaseUrl } from '@utils/url';
import MailIcon from 'icons/mail.svg';
import PhoneIcon from 'icons/smartphone.svg';

async function getFooterData(): Promise<FooterType> {
  const footerData = await getFooterSettings().then(response => response.json);
  return footerData;
}

function Footer() {
  const { isError, isLoading, data } = useQuery(['footerData'], () => getFooterData(), {
    staleTime: 1000 * 60 * 5,
  });

  if (isLoading) return <footer className='footer' />;
  if (isError) return <footer className='footer' />;

  return (
    <footer className='footer'>
      <div className='footer__container'>
        <div className='footer__links'>
          {data.footer_menu.map(item => (
            <Link
              href={
                item.link_intern_full_url
                  ? removeBaseUrl(item.link_intern_full_url)
                  : item.link_extern
              }
              passHref
              key={`${item.title}`}
              target={!item.link_intern_full_url ? '_blank' : '_self'}
              className='footer__link'
            >
              {item.title ?? (item.link_intern_full_url || item.link_extern)}
            </Link>
          ))}
        </div>
        <div className='footer__company-contact'>
          <div className='footer__company-contact-header'>{data.company_contact_name}</div>
          <div>
            {data.company_contact_adress1}
            <br />
            {data.company_contact_adress2}
            <br />
            {data.company_contact_postal1 ? (
              <>
                {data.company_contact_postal1}
                <br />
              </>
            ) : null}
            {data.company_contact_postal2 ? (
              <>
                {data.company_contact_postal2}
                <br />
              </>
            ) : null}
          </div>
          <div className='footer__company-contact-phone'>
            {data.company_contact_mail ? (
              <a
                className='footer__company-contact-link'
                href={`mailto:${data.company_contact_mail}`}
              >
                {data.company_contact_mail} <MailIcon className='footer__contact-svg' />
              </a>
            ) : null}
            {data.company_contact_phone ? (
              <div className='footer__company-contact-flex'>
                Fon{' '}
                <Link
                  className='footer__company-contact-link'
                  href={`tel:${data.company_contact_phone?.replace(/[^A-Z0-9+]+/gi, '')}`}
                >
                  {data.company_contact_phone} <PhoneIcon className='footer__contact-svg' />
                </Link>
              </div>
            ) : null}
          </div>
          {data.company_contact_fax ? <>Fax {data.company_contact_fax}</> : null}
        </div>
        <div className='footer__factory-contact'>
          <div className='footer__factory-contact-header'>{data.factory_contact_name}</div>
          <div>
            {data.company_contact_name}
            <br />
            {data.factory_contact_adress1}
            <br />
            {data.factory_contact_adress2}
          </div>
          <div className='footer__factory-contact-phone'>
            {data.factory_contact_phone ? (
              <div className='footer__factory-contact-flex'>
                Fon{' '}
                <Link
                  className='footer__company-contact-link'
                  href={`tel:${data.factory_contact_phone?.replace(/[^A-Z0-9+]+/gi, '')}`}
                >
                  {data.factory_contact_phone} <PhoneIcon className='footer__contact-svg' />
                </Link>
              </div>
            ) : null}
            {data.factory_contact_fax ? <>Fax {data.factory_contact_fax}</> : null}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
