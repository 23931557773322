import React, { PropsWithChildren } from 'react';
import NavMainAside from '@components/NavMainAside';
import mediaQuery from 'hooks/mediaQuery';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import LoginBackLink from '@components/LoginBackLink';
import Script from 'next/script';
import Footer from '../Footer';
import Header from '../Header';
import Main from '../Main';

type Props = {};

function Layout(props: PropsWithChildren<Props>) {
  const { children } = props;
  const isDesktop = mediaQuery('(min-width: 65em)');
  const router = useRouter();
  let isSignIn = false;
  if (router.asPath.includes('/auth/signin')) {
    isSignIn = true;
  }

  const session = useSession();
  return (
    <div className='layout'>
      <div className='layout__content'>
        {isDesktop && !isSignIn && session.status === 'authenticated' ? (
          <NavMainAside key={router.asPath} />
        ) : null}
        <div className='layout__main'>
          {!isSignIn && session.status === 'authenticated' ? <Header key={router.asPath} /> : null}
          {!isSignIn && session.status !== 'authenticated' ? <LoginBackLink /> : null}
          <Main isSignIn={isSignIn} isLoggedIn={session.status === 'authenticated'}>
            {children}
          </Main>
        </div>
      </div>
      <Footer />
      {process.env.NEXT_PUBLIC_MATOMO_ID ? (
        <Script id='matomo' strategy='afterInteractive' nonce='@@=AAABBBCCCZZZ=@@'>
          {`var _paq = window._paq = window._paq || [];
          _paq.push(['trackPageView']);
          _paq.push(['enableLinkTracking']);
          (function() {
            var u="https://m.werkbank.de/";
            _paq.push(['setTrackerUrl', u+'matomo.php']);
            _paq.push(['setSiteId', '${process.env.NEXT_PUBLIC_MATOMO_ID}']);
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src=u+'m.js'; s.parentNode.insertBefore(g,s);
          })();`}
        </Script>
      ) : null}
    </div>
  );
}

export default Layout;
