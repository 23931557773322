import NavMainMobile from '@components/NavMainMobile';
import mediaQuery from 'hooks/mediaQuery';
import Link from 'next/link';
import { getSession, signOut } from 'next-auth/react';
import MailSvg from 'icons/mail.svg';
import SearchSvg from 'icons/search-magnify.svg';
import { getInboxUnreadNumber } from '@api/wagtail';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import ShutdownSvg from 'icons/shutdown.svg';
import HeaderUserDropdown from './HeaderUserDropdown';
import Logo from '../../../public/lisa_logo.svg';

async function getNumberUnreadMessages(): Promise<number> {
  const session = await getSession();
  const authheader = { Authorization: `Token ${session?.token}` };
  const numberUnread = await getInboxUnreadNumber({ headers: authheader }).then(
    response => response.json,
  );
  return numberUnread.count;
}

function Header() {
  const isMobile = mediaQuery('(max-width: 65em)');

  const [clientWindowHeight, setClientWindowHeight] = useState(0);
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    setClientWindowHeight(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    if (clientWindowHeight > 0 && !scrolled) {
      setScrolled(true);
    } else if (clientWindowHeight === 0) {
      setScrolled(false);
    }
  }, [clientWindowHeight, scrolled]);

  const {
    isError,
    isLoading,
    data: numberUnread,
  } = useQuery(['unread-number'], () => getNumberUnreadMessages(), {
    staleTime: 1000 * 60 * 5,
  });

  return (
    <header
      className={clsx('header', {
        'header--scrolled': scrolled,
      })}
    >
      <div className='header__container'>
        <Link
          href='/'
          passHref
          className='header__logo-container'
          aria-label='Link auf die Startseite'
        >
          <Logo className='header__logo' />
        </Link>
        <div className='header__nav'>
          <Link
            href='/inbox'
            passHref
            className='header__nav-element header-nav__inbox'
            aria-label='Nachrichten anzeigen'
          >
            <MailSvg className='header__nav-element-icon' />
            <span className='header__nav-element-text'>Nachrichten</span>
            {!isLoading && !isError && numberUnread ? (
              <div className='header__nav-inbox-unread'>{numberUnread}</div>
            ) : null}
          </Link>
          <Link
            href='/search'
            passHref
            className='header__nav-element header__nav-element--mobile-hidden'
          >
            <SearchSvg className='header__nav-element-icon magnify' />
            <span className='header__nav-element-text'>Suche</span>
          </Link>
          <HeaderUserDropdown />
          <button className='header__nav-element' onClick={() => signOut()}>
            <ShutdownSvg className='header__nav-element-icon' />
            <span className='header__nav-element-text'>Abmelden</span>
          </button>
          {isMobile ? <NavMainMobile /> : null}
        </div>
      </div>
    </header>
  );
}

export default Header;
