import React, { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import clsx from 'clsx';
import UserSvg from 'icons/user.svg';

function HeaderUserDropdown() {
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const dropdownToggleRef = useRef<HTMLButtonElement | null>(null);
  const [dropdownShow, setDropdownShow] = useState(false);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        dropdownRef.current &&
        dropdownShow &&
        dropdownToggleRef.current &&
        !dropdownToggleRef.current.contains(event.target)
      ) {
        setDropdownShow(!dropdownShow);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [dropdownShow]);
  return (
    <div className='header__nav-element-dropdown-container'>
      <button
        type='button'
        className='header__nav-element'
        aria-label='Benutzer-Dropdown anzeigen'
        ref={dropdownToggleRef}
        onClick={() => {
          setDropdownShow(!dropdownShow);
        }}
      >
        <UserSvg className='header__nav-element-icon' />
        <span className='header__nav-element-text'>Benutzer</span>
      </button>
      <div
        ref={dropdownRef}
        className={clsx('header__nav-element-dropdown', {
          'header__nav-element-dropdown--show': dropdownShow === true,
        })}
      >
        <div className='header__nav-element-dropdown-element'>
          <Link href='/mein-hof' passHref className='header__nav-element-dropdown-link'>
            Mein Hof
          </Link>
        </div>
        <div className='header__nav-element-dropdown-element'>
          <Link href='/user/change-password' passHref className='header__nav-element-dropdown-link'>
            Passwort ändern
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HeaderUserDropdown;
