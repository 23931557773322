import React from 'react';
import Link from 'next/link';
import { removeBaseUrl } from '@/utils/url';
import clsx from 'clsx';
import type { PageMetaDataList } from '@/types';
import { getSession } from 'next-auth/react';
import { getMenu } from '@api/wagtail';
import { useQuery } from '@tanstack/react-query';
import AddSvg from 'icons/add.svg';
import Logo from '../../../public/lisa_logo.svg';

async function getMenuData(): Promise<PageMetaDataList[]> {
  const session = await getSession();
  const authheader = { Authorization: `Token ${session?.token}` };
  const menuData = await getMenu({ headers: authheader }).then(response => response.json);
  return menuData;
}

function NavMainAside() {
  const { isError, isLoading, data } = useQuery(['menuData'], () => getMenuData(), {
    staleTime: 1000 * 60 * 5,
  });

  let currentPath = window.location.pathname;
  if (window.location.pathname !== '/') {
    currentPath = `${window.location.pathname}/`;
  }
  if (isLoading) return <div className='navigation' />;
  if (isError) return <div className='navigation' />;

  return (
    <div className='navigation'>
      <div className='navigation__wrapper'>
        <Link
          href='/'
          passHref
          className='navigation__logo-container'
          aria-label='Link auf die Startseite'
        >
          <Logo className='navigation__logo' />
          <div className='navigation__logo-text'>Lieferanten Informationssystem Ammerland</div>
        </Link>
        <nav className='navigation__nav-container'>
          {/** @ts-expect-error @ */}
          {data.items.map(item => (
            <div className='navigation__element-container' key={`${item.page.slug}`}>
              <Link
                href={removeBaseUrl(item.href)}
                passHref
                className={clsx('navigation__element', {
                  active:
                    (currentPath.includes(removeBaseUrl(item.href)) &&
                      removeBaseUrl(item.href) !== '/' &&
                      currentPath !== '/') ||
                    (removeBaseUrl(item.href) === '/' && currentPath === '/'),
                })}
              >
                {item.text}
                {item.children.length ? (
                  <div className='navigation__has-subnav'>
                    <AddSvg className='navigation__subnav-icon' />
                  </div>
                ) : null}
              </Link>
              {item.children.length ? (
                <div className='navigation__subnav-container'>
                  {/** @ts-expect-error @ */}
                  {item.children.map(child => (
                    <div
                      className='navigation__subnav-element-container'
                      key={`${child.page.slug}`}
                    >
                      <Link
                        href={removeBaseUrl(child.href)}
                        passHref
                        className={clsx('navigation__subnav-element', {
                          active: currentPath.includes(removeBaseUrl(child.href)),
                        })}
                      >
                        {child.text}
                        {child.children.length ? (
                          <div className='navigation__has-subnav'>
                            <AddSvg className='navigation__subnav-icon' />
                          </div>
                        ) : null}
                      </Link>
                      {child.children.length ? (
                        <div className='navigation__subnav-container layer2'>
                          {/** @ts-expect-error @ */}
                          {child.children.map(subchild => (
                            <div
                              className='navigation__subnav-element-container-layer2'
                              key={`${subchild.page.slug}`}
                            >
                              <Link
                                href={removeBaseUrl(subchild.href)}
                                passHref
                                className={clsx('navigation__subnav-element-layer2', {
                                  active: currentPath.includes(removeBaseUrl(subchild.href)),
                                })}
                              >
                                {subchild.text}
                              </Link>
                            </div>
                          ))}
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          ))}
        </nav>
      </div>
    </div>
  );
}

export default NavMainAside;
