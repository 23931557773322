import React from 'react';
import Link from 'next/link';
import ArrowLeftSvg from 'icons/arrow-left.svg';

function LoginBackLink() {
  return (
    <header className='login__back-link'>
      <div className='login__back-container'>
        <Link href='/auth/signin' passHref className='login__back'>
          <ArrowLeftSvg className='login__back-arrow' />
          zurück zum Login
        </Link>
      </div>
    </header>
  );
}

export default LoginBackLink;
